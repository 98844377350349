@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

.app {
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  word-wrap: break-word;
  word-break: keep-all;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
}

.app-header {
  background-color: #3c3e57;
  min-height: 50px;
  color: #ded2aa;
}

.app-header-text {
  font-family: 'Lato', sans-serif;
  outline-style: solid;
  outline-width: 3px;
  outline-color: #ded2aa;
}

.app-footer {
  background-color: #e6e6e6;
  height: 40px;
  color: black;
  font-family: 'Noto Sans KR', sans-serif;
}

.app-fluid {
  padding-left: 0;
  padding-right: 0;
}

.map {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #AAAAAA;
  height: 330px;
}

.register {
  bottom: 0px;
  position: fixed;
  z-index: 5;
  width: 100%;
  max-width: 800px;
}

.register-form {
  text-align: left;
  max-width: 800px;
  width: 100%;
  height: 100%;
}

.register-form-inner {

  width: 400px;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.register-form-inner .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-form-inner .attend-type {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.register-form-inner .form-check {
  padding-right: 1rem;
}

.register-form-inner .basic {
  width: 200px;
  margin-bottom: 0.5rem;
}

.register-form-submit {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.comment-waiting {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #AAAAAA;
}

.comment-loaded {
  background-color: #ffffff;
}

.comment-loaded-back {
  min-height: 180px;
}
